// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD6dEcY2zQK163wHEmotmzKkbt8RaHXrXU",
  authDomain: "hurvant-bdb6e.firebaseapp.com",
  projectId: "hurvant-bdb6e",
  storageBucket: "hurvant-bdb6e.appspot.com",
  messagingSenderId: "780860712839",
  appId: "1:780860712839:web:db6da1e58d962e1facd522",
  measurementId: "G-1FKE08MTY5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
const analytics = getAnalytics(app);