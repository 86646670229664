import React from 'react';
import Nav from '../components/Nav';
import SmallCard from '../components/SmallCard';
import StairPhoto from '../assets/escalera.webp';
import InteriorPhoto from '../assets/interior.webp';
import GardenPhoto from '../assets/garden.webp';
import InstallationPhoto from '../assets/installation.webp';
import HappinessPhoto from '../assets/happiness.webp';
import '../styles/Home.css';

function Home() {
    const handleMailButtonClick = () => {
        window.location.href = 'mailto:hello@hurvant.com';
    }
    return(
        <body>
            <Nav></Nav>
            <section className='welcome'>
                <h1 className='title'>Servicios al alcance de tu mano.</h1>
                <button className='actionButton' onClick={handleMailButtonClick}>Contáctanos</button>
            </section>
            <section className='infoSection'>
                <h2 className='infoTitle'>¿A qué nos dedicamos?</h2>
                <p className='infoText'>
                    Nos especializamos en convertir tus sueños de hogar en una realidad tangible. Somos una firma dedicada a la transformación de espacios residenciales, ofreciendo servicios integrales de reforma y renovación que elevan el confort, la funcionalidad y el estilo de tu hogar.
                </p>
                <div className='cardSection'>
                    <SmallCard title='Interiores' image={StairPhoto}></SmallCard>
                    <SmallCard title='Diseño' image={InteriorPhoto}></SmallCard>
                    <SmallCard title='Jardines' image={GardenPhoto}></SmallCard>
                    <SmallCard title='Instalaciones' image={InstallationPhoto}></SmallCard>
                </div>
            </section>
            <section className='callingAction'>
                <div className='callingContainer'>
                    <div className='textZone'>
                        <p className='callingTitle'>Porque te lo mereces.</p>
                        <p className='callingSub'>Tu hogar no es solo un lugar donde vives, es el refugio de tu alma, el santuario donde te recargas de energía y encuentras paz después de un largo día. Entonces, ¿por qué conformarte con menos de lo que realmente deseas y mereces?</p>
                        <button onClick={handleMailButtonClick} className='callingButton'>Contáctanos</button>
                    </div>
                    <div className='imageZone'>
                    </div>
                </div>
            </section>
        </body>
    )
}

export default Home;