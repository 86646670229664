import React from 'react';
import '../styles/SmallCard.css';

interface Props {
    title: string;
    image: string;
}

const SmallCard: React.FC<Props> = ({ title, image }) => {
    return (
        <div className="cardContainer">
            <h1 className='cardTitle'>{title}</h1>
            <img src={image} alt="Servicio" className='cardImage'/>
        </div>
    );
};

export default SmallCard;