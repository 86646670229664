import React from 'react';
import Nav from '../components/Nav';
import '../styles/About.css';

function About() {
    return(
        <body>
            <Nav></Nav>
            <section className='introduction'>
                <h1 className='mainTitle'>Sobre nosotros</h1>
                <p className='iParagraph'>En Hurvant nos apasiona la transformación de espacios, convirtiendo sueños en realidad y capturando la esencia única de cada cliente proyectándolas en su hogar.</p>
                <p className='iParagraph'>Nuestra excelencia se evidencia en cada detalle, fusionando meticulosamente el diseño excepcional y devoción por capturar las aspiraciones individuales, culminando en hogares distintivos y personalizados.</p>
            </section>
        </body>
    )
}

export default About;